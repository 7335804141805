export default function Header(){
    return(
        <>
        <div className="logo">
        <img
            rel="preconnect"
            loading='lazy'
            src='/living-the-dream.png'
            height={'120em'}
            alt='logo'
        />
        <a aria-label="link to living the dream" target="_blank" rel="noreferrer" href="https://www.livingthedreamagency.com/"><button className='btn-home'>Home</button></a>
        </div>
        </>
    )
}