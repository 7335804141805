export default function Requirements(){
    return(
        <>
        <div className="wrapper-req">
           <header>
                <h3 style={{textAlign: "center"}}>¿Qu&eacute; vas a ver en el video? </h3>
           </header>
           <section className="container-req">
               <article>
                  <img src="/check.svg" alt='check'/>
                  <p> No buscamos gente que quiera trabajar.</p>
               </article>
               <article>
                  <img src="/check.svg" alt='check'/>
                  <p>Somos una agencia reclutadora de estudiantes.</p>
               </article>
               <article>
                  <img src="/check.svg" alt='check'/>
                  <p>El proceso con nuestra agencia no tiene ning&uacute;n costo.</p>
               </article>
               <article>
                  <img src="/check.svg" alt='check'/>
                  <p>Los Webinars son gratuitos o en todo caso reembolsables.</p>
               </article>
               <article>
                  <img src="/check.svg" alt='check'/>
                  <p>Requsitos necesarios para estudiar.</p>
               </article>
           </section>
        </div>
        </>
    )
}