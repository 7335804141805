import { useEffect, useRef, useState } from "react";
import Span from "./Span";

export default function Banner(){

    const [progress, setProgress] = useState(0)
    const [playAudio, isPlayAudio] = useState(false)
    const [isPlaying, setIsPlaying] = useState(false)

    const video = useRef()

    const [loading, setLoading] = useState(true);
    useEffect(() => {
      setTimeout(() => {
        setLoading(false);
      }, 2000)
    }, []);

    const handleProgress = (e) =>{
        if(!isNaN(e.target.duration)){
                setProgress(Math.floor((e.target.currentTime / e.target.duration) * 400)); 
        }
    }

    const play = () => {
        if (playAudio && video.current.paused && !video.current.ended){
            video.current.play();
            setIsPlaying(false)
        }
    }
    const pause = () => {
        if (playAudio && !video.current.paused && !video.current.ended){
            video.current.pause();
            setIsPlaying(true)
        }
    }

    

    const audioPlay = () => {
        video.current.muted = false
        isPlayAudio(true)
        video.current.currentTime = 0
    }


    return(
        <>
        <header>
            <h1 style={{textAlign:"center", marginTop:'0.5em'}}>
                Cumple la vida de tus sue&ntilde;os mientras estudias y vives una experiencia inigualable
            </h1>
        </header>
        <section style={{display:'flex', alignItems:'center', justifyContent:'center', margin:'0 0 3rem 0'}}>
            <div className="video" >
                <video autoPlay muted playsInline={true} ref={video} src="/video.mp4" onTimeUpdate={handleProgress} poster=""></video>
                <div style={{display:"flex", width:'100%'}}>
                    {isPlaying ? <img className="btn-PlayPause" src="/play-fill.svg" alt="play" width={30} onClick={play}/> : <img className="btn-PlayPause" src="/pause.svg" alt="pause" width={30} onClick={pause}/> }
                    <progress id="progress" max="100" value={progress}/>
                </div>
            </div>
            {!playAudio &&
            <div className="btn-reproducir" onClick={audioPlay}>
                <h3>Da click para escuchar el video</h3>
                <img style={{alignSelf:'center'}} src="/play-fill.svg" alt="play" width={50}/>
            </div>
            }
        </section>
        <section>
            <Span/>
        </section>
        </>
    )
}