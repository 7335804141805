import './App.css'
import Banner from './Components/Banner'
import Footer from './Components/Footer'
import Header from './Components/Header'
import Requirements from './Components/Requirements'

function App() {

  return (
    <>
    <main>
      <section className='gradient'>
        <article className='container-info'>
            <Header/>
            <Banner/>
        </article>
      </section>
      <section className='container'>
        <article>
          <Requirements/>  
        </article>
      </section>
      <Footer/>
    </main>
    </>
  )
}

export default App
